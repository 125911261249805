<!-- 管理员导航 -->
<template>
  <div>
    <el-menu
      :default-active="this.$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      router
      :collapse="isCollapse"
      :mode="ismode"
    >
      <!--            <el-submenu :index="items.label" v-for="(items,d) in hasChildren" :key="d">-->
      <el-menu-item
        :index="item.path"
        v-for="item in noChildren"
        :key="item.label"
        :style="{ display: item.roles.length != 0 ? 'block' : 'none' }"
        @click="Navnuns(item.path)"
      >
        <i :class="item.icon"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>

      <el-submenu
        :index="items.label"
        v-for="(items, d) in hasChildren"
        :key="d"
        :style="{ display: items.roles.length != 0 ? 'block' : 'none' }"
      >
        <template slot="title">
          <i :class="items.icon"></i>
          <span slot="title">{{ items.label }}</span>
        </template>
        <el-menu-item
          :index="subitem.path"
          v-for="subitem in items.children"
          :key="subitem.path"
          :style="{ display: subitem.roles.length != 0 ? 'block' : 'none' }"
          @click="Navnuns(subitem.path)"
          >{{ subitem.label }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number: 100,
      navnuns: 0,
      isCollapse: false,
      ismode: "",
      active: this.$route.path,
      asideMenu: [
        //admin
        {
          label: "文章管理",
          icon: "el-icon-document-copy",
          roles: ["*:*", "article:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          children: [
            {
              path: "/notice",
              label: "通知公告",
              roles: ["*:*", "article:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/newpolicy",
              label: "最新政策",
              roles: ["*:*", "article:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
            },
            {
              path: "/guided",
              label: "操作指南",
              roles: ["*:*", "article:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/talent/policy/backstage",
              label: "人才政策",
              roles: ["*:*", "article:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
          ],
        },
        {
          label: "申报管理",
          icon: "el-icon-tickets",
          roles: ["*:*"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          children: [
            {
              path: "/policylist",
              label: "申报设置",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/sbgoverning",
              label: "申报审核",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/feedback",
              label: "反馈管理",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/supervisetype",
              label: "类型管理",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
          ],
        },
        {
          label: "留言管理",
          path: "/exmdiscuss",
          roles: ["*:*"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          icon: "el-icon-edit-outline",
        },
        {
          label: "统计信息",
          icon: "el-icon-s-data",
          roles: ["*:*"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          children: [
            {
              path: "/overview",
              label: "数据总览",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/selectionlist",
              label: "人才认证管理",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/businessmgt",
              label: "单位认证管理",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
          ],
        },
        {
          label: "系统设置",
          icon: "el-icon-setting",
          roles: ["*:*"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          children: [
            {
              path: "/rolelist",
              label: "角色管理",
              roles: ["*:*"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
          ],
        },
        {
          label: "乡贤管理",
          path: "/townsmen",
          roles: ["*:*", "worthy:create", "worthy:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          icon: "el-icon-s-custom",
        },
        //单位
        {
          label: "单位首页",
          roles: ["enterprise:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          path: "/main/enterprise",
          icon: "el-icon-setting",
        },
        {
          label: "认证管理",
          roles: ["enterprise:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          icon: "el-icon-setting",
          children: [
            {
              path: "/qyprocessing",
              label: "单位认证",
              roles: ["enterprise:read", "enterprise:update"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/examinelist",
              label: "人才审核",
              roles: ["enterprise:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
          ],
        },
        {
          label: "申报管理",
          roles: ["enterprise:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          icon: "el-icon-setting",
          children: [
            {
              path: "/talentshenb",
              label: "人才申报",
              roles: ["enterprise:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
            {
              path: "/meshenb",
              label: "自有申报",
              roles: ["enterprise:read"].filter(
                (item) =>
                  JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
              ),
              icon: "el-icon-setting",
            },
          ],
        },
        //个人
        {
          label: "个人首页",
          roles: ["talent:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          path: "/main/talent",
          icon: "el-icon-setting",
        },
        {
          label: "认证管理",
          roles: ["talent:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          path: "/probate",
          icon: "el-icon-setting",
        },
        {
          label: "申报管理",
          roles: ["talent:read"].filter(
            (item) =>
              JSON.parse(sessionStorage.getItem("Roles")).indexOf(item) > -1
          ),
          path: "/declarelist",
          icon: "el-icon-setting",
        },
      ],
    };
  },
  computed: {
    noChildren() {
      return this.asideMenu.filter((item) => !item.children);
    },
    hasChildren() {
      return this.asideMenu.filter((item) => item.children);
    },
  },
  created() {
    //console.log(sessionStorage.getItem("Roles"))
  },
  mounted() {
    if (this.commonFunction._isMobile()) {
      this.isCollapse = true;
      this.ismode = "horizontal";
    } else {
      this.isCollapse = false;
      this.ismode = "vertical";
    }
  },
  methods: {
    handleOpen(key, keyPath) {},
    Navnuns(Path) {
      //console.log(this.navnuns)
      console.log(Path === this.$route.path);

      // if (this.navnuns===0&&Path!=this.$route.path){
      //     this.navnuns = 2
      // }else {
      //     location.reload()
      // }
    },

    handleClose(key, keyPath) {},
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  text-align: left;
  border: 0;
}
</style>
