<!-- 后台主页 -->
<template>
  <div class="mains">
    <el-container class="boxs">
      <el-aside width="240px" style="background-color: #fff">
        <div class="leix" v-if="leix === 'talent'">
          <img src="../assets/ptwenz1.jpg" alt />
        </div>
        <div class="leix" v-else-if="leix === 'enterprise'">
          <img src="../assets/ptwenz2.jpg" alt />
        </div>
        <div class="leix" v-else-if="leix === 'worthyManager'">
          <img src="../assets/ptwenz3.png" alt />
        </div>
        <div v-else class="leix">
          <img src="../assets/ptwenz.jpg" alt />
        </div>
        <navs />
      </el-aside>
      <el-container>
        <el-header style="height:96px;text-align: right; font-size: 12px">
          <img
            src="../assets/ptlogo.png"
            alt
            @click="request"
            style="float: left;padding: 28px 0 0 0;cursor:pointer"
          />
          <el-dropdown
            style="height: 50px;line-height: 50px;"
            :style="isMobile ? 'float: right;' : ''"
          >
            <span class="el-dropdown-link">
              {{ user }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="lid">
                <el-button
                  plain
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="changePassword"
                  >修改密码
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item class="lid">
                <el-button
                  plain
                  size="small"
                  icon="el-icon-phone-outline"
                  @click="requestOpenImg"
                  >修改手机号码</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item class="lid">
                <el-button
                  plain
                  size="small"
                  icon="el-icon-close"
                  @click="signout"
                  >退出登录</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div
            class="block"
            style="float: right;padding: 29px 8px 0;height: 40px;line-height: 40px;"
          >
            <el-avatar :size="40" :src="circleUrl"></el-avatar>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>当前收到{{ number }}条审核信息</div>
      <div>当前收到{{ number }}条审核信息</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="修改密码"
      :visible.sync="centerDialogVisible"
      width="520px"
      center
      lock-scroll
      :showClose="showClo"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            placeholder="请输入旧密码"
            show-password
            type="password"
            clearable
            v-model="form.oldPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="newPassword">
          <el-input
            placeholder="请输入新密码"
            show-password
            type="password"
            clearable
            v-model="form.newPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input
            placeholder="请确定密码"
            show-password
            type="password"
            clearable
            v-model="form.confirmPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="image">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                placeholder="请输入图形验证码"
                clearable
                v-model="form.image"
              />
            </el-col>
            <el-col :span="10">
              <div @click="requestImg">
                <!--suppress HtmlUnknownTarget -->
                <img :src="image" @click="requestImg" alt="快点我一下" />
              </div>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button
                type="primary"
                @click="modifyPassword('form')"
                style="color: #fff"
                >确定</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="requestCancel('form')"
                style="color: #fff"
                >取消</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <!--                <a id="password-a" @click="requestForget" style="cursor:pointer">忘记密码？</a>-->
      </el-form>
    </el-dialog>

    <el-dialog
      title="修改手机号码"
      :visible.sync="phoneDialog"
      width="520px"
      center
      lock-scroll
      :showClose="showClo"
      :before-close="handleClose"
    >
      <el-form
        ref="phoneForm"
        :model="phoneForm"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="旧手机号" prop="oldCellphone">
          <el-input
            placeholder="请输入旧手机号"
            clearable
            v-model="phoneForm.oldCellphone"
          ></el-input>
        </el-form-item>

        <el-form-item label="新手机号" prop="newCellphone">
          <el-input
            placeholder="请输入新手机号"
            clearable
            v-model="phoneForm.newCellphone"
          ></el-input>
        </el-form-item>

        <el-form-item label="图形验证码" prop="imageVerifyCode">
          <el-row :gutter="20">
            <el-col :span="14">
              <el-input
                placeholder="请输入图形验证码"
                clearable
                v-model="phoneForm.imageVerifyCode"
              />
            </el-col>
            <el-col :span="8">
              <div>
                <!--suppress HtmlUnknownTarget -->
                <img :src="image" @click="requestImg" alt="快点我一下" />
              </div>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="手机验证码" prop="smsVerifyCode">
          <el-row :gutter="20">
            <el-col :span="14">
              <el-input
                placeholder="手机验证码"
                v-model="phoneForm.smsVerifyCode"
                clearable
              />
            </el-col>
            <el-col :span="8">
              <div @click="requestImg">
                <el-button
                  type="mini"
                  :disabled="disabled"
                  @click="getCode"
                  v-html="Btntxt"
                ></el-button>
              </div>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button
                type="primary"
                @click="modifyPhone('phoneForm')"
                style="color: #fff"
                >确定</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="requestCancelPhone('phoneForm')"
                style="color: #fff"
                >取消</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="忘记密码"
      :visible.sync="forgetDialog"
      width="30%"
      center
      lock-scroll
      v-loading="loading"
      :showClose="showClo"
    >
      <el-form
        ref="newForm"
        :model="newForm"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            placeholder="请输入新密码"
            show-password
            type="password"
            clearable
            v-model="newForm.newPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            placeholder="请确认新密码"
            show-password
            type="password"
            clearable
            v-model="newForm.confirmPassword"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phoneNumber">
          <el-input
            placeholder="请输入手机号"
            clearable
            v-model="newForm.phoneNumber"
          ></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="image">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                placeholder="请输入图形验证码"
                clearable
                v-model="newForm.image"
              />
            </el-col>
            <el-col :span="10">
              <div>
                <!--suppress HtmlUnknownTarget -->
                <img :src="image" alt="快点我一下" @click="requestImg" />
              </div>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="验证码" prop="codeNumber">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                placeholder="请输入短信验证码"
                v-model="newForm.codeNumber"
              />
            </el-col>
            <el-col :span="8">
              <el-button type="mini" @click="requestCode">获取验证码</el-button>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button type="primary" @click="forgetPassword('newForm')"
                >确定</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button type="primary" @click="back('newForm')"
                >返回</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import navs from "../components/ControlPanel/nav";

export default {
  components: {
    navs,
  },
  provide() {
    return {
      reload: this.reload,
      rotate: false,
    };
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新的手机号码"));
      } else if (value === this.phoneForm.oldCellphone) {
        callback(new Error("新手机号与旧手机号重复！"));
      } else {
        callback();
      }
    };
    var validateOldPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧的手机号码"));
      } else if (value !== this.phone) {
        console.log(value);
        console.log(this.phone);
        callback(new Error("旧手机号错误！"));
      } else {
        callback();
      }
    };
    return {
      Btntxt: "获取验证码",
      time: 60,
      isMobile: false,
      number: 100,
      showClo: false,
      rotate: false,
      loading: false,
      isPassd: false,
      dialogVisible: false,
      disabled: false,
      isRouterAlive: true,
      centerDialogVisible: false,
      phoneDialog: false,
      forgetDialog: false,
      phone: "",
      phoneForm: {
        imageVerifyCode: "",
        imageVerifyId: "",
        newCellphone: "",
        oldCellphone: "",
        smsVerifyCode: "",
        smsVerifyId: "",
        username: sessionStorage.getItem("user"),
      },
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        image: "",
        imageVerifyId: "",
      },
      newForm: {
        phoneNumber: "",
        newPassword: "",
        confirmPassword: "",
        codeNumber: "",
        smsVerifyId: "",
        image: "",
        imageVerifyId: "",
        username: "",
      },
      passwordForm: {
        captcha_code: "",
      },
      image: "",
      codeNumber: "",
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        oldCellphone: [
          { required: true, validator: validateOldPhone, trigger: "blur" },
          // {required: true, message: "请输入旧的手机号码", trigger: "blur"},
          {
            pattern: /^0{0,1}(13[0-9]|15[7-9]|150|151|153|156|18[2-9])[0-9]{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        newCellphone: [
          { required: true, validator: validatePhone, trigger: "blur" },
          {
            pattern: /^0{0,1}(13[0-9]|15[7-9]|150|151|153|156|18[2-9])[0-9]{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        imageVerifyCode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        image: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        smsVerifyCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^0{0,1}(13[0-9]|15[7-9]|150|151|153|156|18[2-9])[0-9]{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        codeNumber: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },
      user: "",
      leix: "",
      id: "",
      deposit: {
        id: "",
        password: "",
        // roleIds: [],
        status: 1,
      },
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      squareUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      sizeList: ["large", "medium", "small"],
    };
  },

  //用于数据初始化
  created() {
    this.user = sessionStorage.getItem("user");
    this.deposit.id = sessionStorage.getItem("ID");
    this.userId = sessionStorage.getItem("userId");
    this.leix = sessionStorage.getItem("Leix");
    this.mobilePhone();
  },
  mounted() {
    if (this.commonFunction._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    requestOpenImg() {
      console.log(this.phone);
      this.phoneDialog = true;
      this.mobilePhone();
      this.requestImg();
    },

    // 查找手机号
    mobilePhone() {
      let urlss = "/" + this.leix + "/detail?id=";
      this.$axios.get(urlss + this.deposit.id).then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          console.log(response.data.data);
          this.phone = response.data.data.account.contact;
        }
      });
    },

    // 修改手机号短信验证
    requestVerificationCode() {
      let self = this;
      if (this.phoneForm.oldCellphone === "") {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 3000);
        this.$root.warn("请输入旧手机号");
        return false;
      } else if (this.phoneForm.imageVerifyCode === "") {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 3000);
        this.$root.warn("请输入短信验证码");
        return false;
      } else {
        this.$axios
          .patch("/account/cellphone", {
            oldPassword: self.form.oldPassword,
            newPassword: self.form.newPassword,
            imageVerifyCode: self.form.image,
            username: self.user,
            imageVerifyId: self.form.imageVerifyId,
          })
          .then((response) => {
            self.$root.success("已向您的旧手机发送短信，请注意查收！");
          });
      }
    },
    handleOpen(key, keyPath) {},

    ndleClose(key, keyPath) {},

    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    //  退出
    signout() {
      this.$confirm("确定退出系统？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/");
          sessionStorage.clear();
          this.$message({
            type: "success",
            message: "成功退出系统!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出系统",
          });
        });
    },

    settings() {
      this.$router.push("/settings");
      this.$store.commit("selectMenu", {
        icon: "el-icon-setting",
        label: "系统设置",
        path: "/settings",
      });
    },

    //  打开修改密码
    changePassword() {
      this.centerDialogVisible = true;
      this.requestImg();
    },

    //  获取图形验证码
    requestImg() {
      let self = this;
      self.$axios
        .get("/verify/image")
        .then((response) => {
          self.image = response.data.data.image;
          self.form.imageVerifyId = response.data.data.id;
          self.phoneForm.imageVerifyId = response.data.data.id;
          self.newForm.imageVerifyId = response.data.data.id;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //  确定修改密码
    modifyPassword(formName) {
      let self = this;
      self.$refs[formName].validate((valid) => {
        if (valid) {
          self.$axios
            .put("/account/password", {
              oldPassword: self.form.oldPassword,
              newPassword: self.form.newPassword,
              imageVerifyCode: self.form.image,
              username: self.user,
              imageVerifyId: self.form.imageVerifyId,
            })
            .then((response) => {
              console.log(response);
              if (response.data.status === 200) {
                self.$root.success("修改成功");
                self.form = {};
                self.centerDialogVisible = false;
              } else if (response.data.data === "图形验证码错误") {
                self.$root.warn("图形码错误");
                self.form.image = "";
              } else if (response.data.data === "原密码错误") {
                self.$root.warn("旧密码错误 请重新输入");
                self.form.oldPassword = "";
                self.form.image = "";
                self.requestImg();
              } else if (self.form.newPassword !== self.form.confirmPassword) {
                self.$root.warn("密码不一致 请重新输入！");
                self.form.image = "";
                self.form.newPassword = "";
                self.form.confirmPassword = "";
                self.requestImg();
              } else if (self.form.oldPassword === self.form.newPassword) {
                self.$root.warn("修改密码与旧密码一致！");
                self.form.image = "";
                self.form.newPassword = "";
                self.form.confirmPassword = "";
                self.image = "";
                self.requestImg();
              } else {
                self.$root.warn("修改失败");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },

    //  修改手机号码
    modifyPhone(formName) {
      let self = this;
      console.log(self.phoneForm);
      self.$refs[formName].validate((valid) => {
        if (valid) {
          self.$axios
            .patch("/account/cellphone", self.phoneForm)
            .then((response) => {
              if (response.data.status === 200) {
                self.$root.success("修改成功");
                self.phoneDialog = false;
                self.phoneForm = {};
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },

    //  忘记密码
    forgetPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.newForm.newPassword !== this.newForm.confirmPassword) {
            this.$root.warn("密码不一致 请重新输入！");
            this.newForm.image = "";
            this.newForm.newPassword = "";
            this.newForm.confirmPassword = "";
            this.newForm.codeNumber = "";
            this.requestImg();
            return;
          }
          this.$axios
            .put("/account/password/forgot", {
              imageVerifyId: this.newForm.imageVerifyId,
              imageVerifyCode: this.newForm.image,
              smsVerifyId: this.newForm.smsVerifyId,
              smsVerifyCode: this.newForm.codeNumber,
              username: this.user,
              newPassword: this.newForm.newPassword,
            })
            .then((response) => {
              if (response.data.status === 200) {
                this.$root.success("修改成功");
                this.forgetDialog = false;
              } else if (response.data.data === "图形验证码错误") {
                this.$root.warn("图形码错误");
                this.newForm.image = "";
                this.newForm.codeNumber = "";
                return;
              } else if (response.data.data === "短信验证码错误") {
                this.$root.warn("短信验证码错误");
                this.newForm.codeNumber = "";
              } else {
                this.$root.warn("请获取短信验证码");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          return false;
        }
      });
    },

    //  取消修改
    requestCancel(formName) {
      this.$refs[formName].resetFields();
      this.centerDialogVisible = false;
    },
    // 取消手机号修改
    requestCancelPhone(formName) {
      this.$refs[formName].resetFields();
      this.phoneDialog = false;
    },
    handleClose() {
      this.form = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        image: "",
        imageVerifyId: "",
      };
      this.centerDialogVisible = false;
    },

    //   跳转到忘记密码弹窗
    requestForget() {
      this.centerDialogVisible = false;
      this.forgetDialog = true;
      this.requestImg();
    },

    //  获取手机验证码
    requestCode() {
      this.$axios
        .get("/verify/sms", {
          params: {
            imageVerifyCode: this.newForm.imageVerifyCode,
            imageVerifyId: this.newForm.imageVerifyId,
            username: this.user,
          },
        })
        .then((response) => {
          console.log(response);
          if (this.newForm.image.length === 0) {
            this.$root.warn("请先输入图形验证码");
            return;
          }
          this.newForm.smsVerifyId = response.data.data;
        });
    },

    // 修改手机号 获取手机验证码
    getCode() {
      let CodeData = {
        imageVerifyCode: this.phoneForm.imageVerifyCode,
        imageVerifyId: this.phoneForm.imageVerifyId,
        username: this.user,
      };
      console.log(CodeData);
      this.$axios
        .get("/verify/sms", {
          params: CodeData,
        })
        .then((response) => {
          console.log(response);
          if (this.phoneForm.imageVerifyCode.length === 0) {
            this.$root.warn("请先输入图形验证码");
            return;
          }

          this.time = 60;
          this.timer();
          this.phoneForm.smsVerifyId = response.data.data;
          this.$root.success("已向您的旧手机发送短信，请注意查收！");
        });
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.Btntxt = this.time + "s后重新获取";
        //console.log(this.btntxt)
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.Btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    //  返回
    back(formName) {
      this.$refs[formName].resetFields();
      this.forgetDialog = false;
      this.centerDialogVisible = true;
    },
  },

  beforeCreate: function() {},
};
</script>

<style lang="less" scoped>
.el-header {
  height: 96px;
  background-color: #fff;
  color: #333;
  line-height: 96px;
}

.el-aside {
  color: #333;
}

.div {
  width: 100%;
  height: 80px;
  background: #9ed1ff;
}

#password-a {
  float: right;
  text-decoration: none;
  color: #e6a23c;
}

.mains,
.boxs {
  height: 100vh;
}

.el-menu {
  text-align: left;
  border: 0;
}

.lid {
  padding: 0 5px;

  button {
    border: 0;
  }
}
@import "../styles/common_comtril.less";
/deep/ .el-pagination ul {
  padding-left: 0;
}
/*@keyframes rotation {*/
/*    from { transform: rotate(0deg); }*/
/*    to { transform: rotate(360deg) }*/
/*}*/
</style>
